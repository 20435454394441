
.button {
    display: inline-block;
    color: white;
    background: url('/images/mediumcherry.png');
    padding: 0.5em 1rem 0.5rem 1rem;
    border-radius: 999rem;
    border: solid 0.2rem black;
    cursor: pointer;
    box-shadow:
        0px 0.5px 1.2px rgba(0, 0, 0, 0.011),
        0px 1.8px 4.6px rgba(0, 0, 0, 0.037),
        0px 5px 10.9px rgba(0, 0, 0, 0.092),
        0px 17px 27px rgba(0, 0, 0, 0.24);

}

.button:hover {
    background: url('/images/lightcherry.png');
}
.button:active {
    background: url('/images/darkcherry.png');
    box-shadow:
        0px 0.2px 0.5px rgba(0, 0, 0, 0.007),
        0px 0.7px 1.7px rgba(0, 0, 0, 0.023),
        0px 2px 4px rgba(0, 0, 0, 0.057),
        0px 7px 10px rgba(0, 0, 0, 0.15);
}



.button, .outlined-text {
    z-index: 0;  /* this is still behind ::before */
    position: relative;
}
.button::before, .outlined-text::before {
    content: attr(data-text);
    position: absolute;
    color: black;
    -webkit-text-stroke: 0.08em black;
    z-index: -1;
}
.button.no-auto-outlined-text::before {
    content: none;
}

svg path.black-outline {
    fill: white;
    stroke: black;
    stroke-width: 0.04em;
}



.checkbox {
    position: relative;
    display: inline-block;
    background: url('/images/mediumcherry.png');
    width: 2em;
    height: 2em;
    border: solid 0.2rem black;
    border-radius: 0.3em;
    cursor: pointer;
    box-sizing: content-box;
}
.checkbox:active {
    background: url('/images/darkcherry.png');
}
.checkbox.checked::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/images/checkmark.png');
    width: 2em;
    height: 2em;
    background-size: 3em;
    background-position: center;
}

